<template>
  <div class="entry">
    <Layout>
      <agency-sidebar v-if="isLoaded"></agency-sidebar>
      <agency-topbar></agency-topbar>

      <Content v-if="isLoaded" :collapse="isMenuOpen">
        <transition name="slide">
          <router-view></router-view>
        </transition>
      </Content>
    </Layout>
  </div>
</template>

<script>
export default {
  components: {
    AgencySidebar: () => import("@/components/Sidebar/AgencySidebar"),
    AgencyTopbar: () => import("@/components/Topbar/AgencyTopbar"),
    Layout: () => import("@/components/GlobalComponents/FdLayout/Layout"),
    Content: () => import("@/components/GlobalComponents/FdLayout/Content")
  },
  name: "home",
  data() {
    return {
      isLoaded: false
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    }
  },
  watch: {},
  mounted() {
    this.init();
  },
  methods: {
    // ============================ INITIALIZATION =============================
    async init() {
      await this.initUserData();
      await this.initPermission();
      this.initMenuState();
      this.isLoaded = true;
    },
    async initUserData() {
      try {
        this.$store.commit("setIsLoading", true);
        const userData = await this.axios.get(
          `${this.$agencyAPI}/account?include=rolePermission,agency`
        );

        this.$auth.user({ ...userData.data, ...userData.data.user });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    initMenuState() {
      window.innerWidth >= 768
        ? this.$store.commit("openSidebar")
        : this.$store.commit("closeSidebar");
    },
    async initPermission() {
      await this.setPermission();
    },

    async setPermission() {
      this.$store.commit("setIsLoading", true);
      await this.$store.dispatch("manageAccount/setAccountPermission");
      this.$store.commit("setIsLoading", false);
    }
  }
};
</script>

<style lang="scss"></style>
